.container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  width: 100%;
  height: 100px;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer img {
  margin-left: 0.5rem;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
} */

/* .title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
} */

.description {
  line-height: 1.5;
  font-size: 1.5rem;
}

.token {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin-top: 3rem;
}

.card {
  margin: 1rem;
  flex-basis: 45%;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h3 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

.title {
	line-height: 1;
	margin: 100px 0 0 0;
	text-transform: none;
	/* letter-spacing: -6px; */
	min-height: 200px;
	display: flex;
	align-items: flex-end;
}

.title-above {
	position: relative;
}

.title-under {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 2;
}
.title-under-blended {
	color: #3a3a3a;
	mix-blend-mode: color-burn;
}

.title-under-overlay {
	opacity: 0.2;
	color: #3a3a3a;
}

.section-title-1 {
  font-size: 54px;
  font-weight: 500;
  /* text-transform: uppercase; */
  width: 500px;
  max-width: 80vw;
}

@media(min-width: 450px) {
  .section-title-1 {
    font-size: 70px;
  }
}

@media(min-width: 600px) {
  .section-title-1 {
    font-size: 90px;
  }
}

@media(min-width: 950px) {
  .section-title-1 {
    font-size: 110px;
    width: 600px;
  }
}

@media(min-width: 1150px) {
  .section-title-1 {
    font-size: 130px;
  }
}
